.module {
  color: $text;
  counter-reset: footnote;
  margin: 0 auto;
  max-width: 750px;
  padding-bottom: 20px;
  width: 90%;

  div {
    line-height: 1.6;
  }

  h1 {
    font-size: 2.2rem;
    margin-bottom: 30px;
    margin-top: 50px;
    text-align: center;

    @media (max-width: $md) {
      font-size: 1.7rem;
      margin-top: 0;
    }
  }

  h2 {
    font-size: 2rem;
    margin: 40px auto;
    text-align: center;
  }

  h3 {
    font-size: 1.5rem;
    margin: 30px 0;
  }

  h4 {
    font-size: 1.3rem;
    margin: 30px 0;
  }

  h5 {
    font-size: 1.1rem;
    margin: 20px 0;
  }

  ul {
    margin: 20px;
    &:first-of-type {
      ul {
        margin-bottom: 0;
        margin-top: 0;
      }
      a {
        text-decoration: none !important;
      }
    }
  }

  &.science {
    img {
      margin: 40px auto;
      max-width: 800px;
      width: 95%;
    }
  }
}

/* Footnotes */
.footnotes {
  display: none;
}

.footnote,
.footnote-ref {
  counter-increment: footnote;
  margin-right: 2px;
  position: relative;
  text-decoration: none;
  top: -5px;
  &:last-child {
    margin-right: 0;
  }
  &::before {
    color: $black;
    content: counter(footnote);
    font-size: 11px;
    font-weight: 700;
  }
}

.affiliate {
  display: inline;
  > button {
    background-color: transparent;
    display: inline;
    font-size: 1rem;
    font-weight: 500;
    padding: 0;
    text-decoration: underline;
  }
}
